import React, { useEffect } from "react";
import logo1 from "./assets/logo-01.svg";
import logo2 from "./assets/logo-02.svg";
import logo3 from "./assets/logo-03.svg";
import logo4 from "./assets/logo-04.svg";
import logo5 from "./assets/logo-05.svg";
import logo6 from "./assets/logo-06.svg";
import logo7 from "./assets/logo-07.svg";
import logo8 from "./assets/logo-08.svg";
import logo9 from "./assets/logo-09.svg";
import logo10 from "./assets/logo-10.svg";
import logo11 from "./assets/logo-11.svg";
import logo12 from "./assets/logo-12.svg";
import avatar from "./assets/avatar.png";

const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12];

const Services = () => {
    useEffect(() => {
        document.title = "Lee Simpson — Product Strategy + Innovation Leadership";
        document.querySelector('meta[name="description"]')?.setAttribute("content", "I work with businesses to identify emerging trends, assess competitive landscapes, and validate new opportunities.");
    }, []);
    return (
        <div>
            <div className="max-w-4xl px-4 sm:px-4 lg:px-8">
                <div className="divide-y divide-gray-300">
                    <div className="sm:px-0 py-8 sm:grid sm:grid-cols-4 items-center">
                        <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-4">
                            <div className="flex gap-4 items-center">
                                <div>
                                    <img alt="User Avatar" src={avatar} className="inline-block size-14 rounded-md" />
                                </div>
                                <div>
                                    <h3 className="text-base/7 font-semibold text-gray-900">Lee Simpson</h3>
                                    <p className="max-w-2xl text-sm/4 text-gray-500">Product Strategy + Innovation Leadership</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-sm text-gray-900 text-right"></div>
                    </div>
                </div>
            </div>
            <div className="border-b border-gray-200"></div>
            <div className="max-w-4xl px-4 sm:px-4 lg:px-8">
                <div className="divide-y divide-gray-100">
                    <div className="py-8">
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4">
                            <div className="mt-1 text-bse text-gray-700 sm:col-span-3 sm:mt-0 space-y-4">
                                <p>I help companies uncover opportunities, develop innovative products, and create strategies for growth. My work is focused on three key areas:</p>
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="mb-4 text-sm/5 font-semibold text-gray-900">Market Intelligence & Opportunity Discovery</div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-4">
                                <p>I work with businesses to identify emerging trends, assess competitive landscapes, and validate new opportunities. By analyzing market data and industry shifts, I help companies understand where to invest and how to position themselves for success.</p>
                                <p>My approach includes evaluating market readiness, assessing competitive positioning, and conducting consumer research to test demand. With a focus on strategic foresight, I help organizations anticipate change and act on it with confidence.</p>
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="mb-4 text-sm/5 font-semibold text-gray-900">Product Strategy & Innovation</div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-4">
                                <p>Bringing new products to market requires more than just an idea. I help businesses translate concepts into scalable products and services that align with market needs. My work includes defining product vision, applying design thinking, and conducting user research to refine solutions.</p>
                                <p>I guide teams through prototyping and testing to validate concepts before launch, ensuring alignment between innovation and business strategy. Whether developing new platforms, digital ecosystems, or customer experiences, I focus on creating solutions that drive impact and long-term value.</p>
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="mb-4 text-sm/5 font-semibold text-gray-900 ">Growth & Market Expansion</div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-4">
                                <p>Scaling a business requires a clear roadmap and an adaptable strategy. I help companies define go-to-market plans, establish success metrics, and build partnerships to expand their reach.</p>
                                <p>By continuously analyzing market feedback and adoption trends, I ensure that product strategies remain responsive and effective. Whether entering new markets, refining business models, or positioning a product for long-term success, I focus on sustainable growth strategies that drive meaningful results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b border-gray-200"></div>
            <div className="max-w-4xl px-4 sm:px-4 lg:px-8">
                <div className="divide-y divide-gray-100">
                    <div className="py-8">
                        <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 gap-2 place-items-center">
                            {logos.map((logo, index) => (
                                <div key={index} className="flex justify-center">
                                    <img src={logo} alt={`Logo ${index + 1}`} className="h-16 object-contain grayscale hover:grayscale-0 transition duration-300" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b border-gray-200"></div>
            <div className="max-w-4xl px-4 sm:px-4 lg:px-8">
                <div className="divide-y divide-gray-100">
                    <div className="py-8">
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0">
                                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-y-2">
                                    {["Go-to-Market Strategy", "Customer Acquisition", "Business Model Scaling", "Performance Analysis", "Strategic Partnerships", "Market Positioning", "Product Roadmapping", "Design Thinking", "Prototyping & Market Testing", "Business Modeling", "Digital Ecosystem Strategy", "Concept Validation", "Trend Analysis", "Competitive Research", "Consumer Insights", "Market Readiness", "Strategic Foresight", "Opportunity Validation"].map((item, index) => (
                                        <div key={index}>{item}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="mb-4 text-sm/5 font-semibold text-gray-900">Let's Talk</div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-8">
                                <div>
                                    <p>If you're looking for strategic guidance or a thought partner to tackle complex challenges and uncover new opportunities, let's connect. Book a one-on-one session to explore how we can work together.</p>
                                </div>
                                <div>
                                    <a href="https://calendar.app.google/5Wx9FWc3NSXHn3pS8" target="_blank" class="group relative inline-flex h-12 items-center justify-center overflow-hidden rounded-md bg-neutral-950 px-6 font-medium text-neutral-200">
                                        <span>Book a Call</span>
                                        <div class="ml-1 transition group-hover:translate-x-1">
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5">
                                                <path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="px-4 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-8">
                                <div>
                                    <a href="/" class="underline hover:no-underline me-2">
                                        Home
                                    </a>
                                    <a href="/" class="underline hover:no-underline">
                                        Work With Me
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
