import "./App.css";
import React, { useEffect, useState } from "react";
import avatar from "./assets/avatar.png";

const ClientsSection = () => {
    // Define clients with categories
    const clients = {
        Entertainment: ["Fox Entertainment", "Netflix", "YouTube", "Warner Bros.", "Paramount", "BBC", "The Guardian", "ITV", "NBCUniversal", "Comcast", "Channel 4", "ABC", "AT&T"],
        Retail: ["American Express", "7-Eleven", "PetSmart", "Lowe's", "Zäbka"],
        Healthcare: ["Exact Sciences", "AdventHealth", "NHS", "Cedars Sinai", "GoodRx", "Macmillan Cancer", "Function Health"],
        Ventures: ["CreatorDAO", "Volta Charging", "Renew", "Selfmade", "PrepTrack"],
        Other: ["Chubb Insurance", "Reckitt", "Salesforce", "Google", "Unity Technologies", "Exceptional Alien", "Sidewalk Labs", "Johnson Controls", "Keurig Dr Pepper", "Post Cereals", "Samsung", "Virgin Casino"],
        Agencies: ["Instrument", "Elephant", "Cactus", "B-Reel", "Deutsch LA", "For People", "Ostmodern", "Product Inc", "Siberia", "Raw Materials", "uneo", "WeLanded"],
    };

    // Get all client categories including "All"
    const clientCategories = ["All", ...Object.keys(clients)];

    // State to store the selected category (default: "All")
    const [selectedCategory, setSelectedCategory] = useState("All");

    // Sort clients alphabetically within each category
    const sortedClients = Object.fromEntries(Object.entries(clients).map(([category, clientList]) => [category, [...clientList].sort()]));

    // Merge all sorted clients for display
    const allClients = Object.values(sortedClients).flat().sort();

    return (
        <div className="py-8">
            <div className="mb-4 text-sm/5 font-semibold text-gray-900">Clients</div>

            <div className="grid sm:grid-cols-4 grid-cols-1 sm:gap-8">
                <div className="grid grid-cols-3 gap-y-1 col-span-3 text-sm/5 text-gray-700 mb-8 sm:mb-0">
                    {allClients.map((client, index) => {
                        const isHighlighted = selectedCategory !== "All" && sortedClients[selectedCategory]?.includes(client);

                        return (
                            <div key={index} className={`${isHighlighted ? "text-rose-600" : "text-gray-700"}`}>
                                {client}
                            </div>
                        );
                    })}
                </div>

                <div className="col-span-1">
                    <ul className="space-y-0.5 text-sm/5 font-normal">
                        {clientCategories.map((category, index) => (
                            <li key={index}>
                                <button className={`text-sm ${selectedCategory === category ? "text-rose-600" : "text-gray-400 hover:text-gray-900"}`} onClick={() => setSelectedCategory(category)}>
                                    {category}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const Home = () => {
    useEffect(() => {
        document.title = "Lee Simpson — Founder + Product Leader";
        document.querySelector('meta[name="description"]')?.setAttribute("content", "Founder and product leader with 20 years of experience helping businesses develop and launch digital products");
    }, []);
    return (
        <div>
            <div className="max-w-4xl px-4 sm:px-4 lg:px-8">
                <div className="divide-y divide-gray-300">
                    <div className="sm:px-0 py-8 sm:grid sm:grid-cols-4 items-center">
                        <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-4">
                            <div className="flex gap-4 items-center">
                                <div>
                                    <img alt="User Avatar" src={avatar} className="inline-block size-14 rounded-md" />
                                </div>
                                <div>
                                    <h3 className="text-base/7 font-semibold text-gray-900">Lee Simpson</h3>
                                    <p className="max-w-2xl text-sm/4 text-gray-500">Founder + Product Leader</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-sm text-gray-900 text-right"></div>
                    </div>
                </div>
            </div>
            <div className="border-b border-gray-200"></div>
            <div className="max-w-4xl px-4 sm:px-4 lg:px-8">
                <div className="divide-y divide-gray-100">
                    <div className="py-8 sm:grid sm:grid-cols-4 sm:gap-8">
                        <div className="text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-4 mb-4 sm:mb-0">
                            <p>I'm Lee Simpson, a founder and product leader with 20 years of experience helping businesses develop and launch digital products. I focus on aligning technology with market needs to build scalable, effective solutions.</p>

                            <p>
                                I work with companies in media and entertainment, healthcare, and retail to develop digital ecosystems, engagement strategies, and new product capabilities. Through my Los Angeles-based consultancy,{" "}
                                <a href="http://www.smpsn-co.com" target="_blank" className="text-rose-600">
                                    smpsn
                                </a>
                                , I partner with brands to navigate complex challenges, uncover new opportunities, and bring innovative products to market.
                            </p>

                            <p>
                                In 2024, I founded{" "}
                                <a href="http://www.creatorplayground.io" target="_blank" className="text-rose-600">
                                    Creator Playground
                                </a>
                                , a company designed to help independent creators and artists scale their businesses. Our mission is to develop new products and platforms that streamline operations, enhance revenue models, and support sustainable growth within the creator economy.
                            </p>
                        </div>
                        <div>
                            <ul className="space-y-0.5 text-sm/5 font-normal">
                                {[
                                    { name: "Threads", url: "https://www.threads.net/@itsleesimpson" },
                                    { name: "Instagram", url: "https://www.instagram.com/itsleesimpson" },
                                    { name: "Bluesky", url: "https://bsky.app/profile/itsleesimpson.bsky.social" },
                                    { name: "LinkedIn", url: "https://www.linkedin.com/in/leesimpson" },
                                ].map((link, index) => (
                                    <li key={index}>
                                        <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-gray-400">
                                            {link.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <ClientsSection />
                    <div className="py-8">
                        <div className="mb-4 text-sm/5 font-semibold text-gray-900 ">Experience</div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-8">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-1">
                                {[
                                    { year: "2024 — Present", role: "Founder", company: "Creator Playground" },
                                    { year: "2020 — Present", role: "Founder & Principal", company: "smpsn" },
                                    { year: "2014 — 2020", role: "Strategy Director, Global", company: "ustwo" },
                                    { year: "2012 — 2014", role: "Product Lead", company: "The Guardian" },
                                    { year: "2012 — 2014", role: "Experience Design Lead", company: "Ostmodern" },
                                    { year: "2008 — 2012", role: "Product Designer", company: "Freelance" },
                                    { year: "2004 — 2008", role: "Founder", company: "Rebelo" },
                                ].map((item, index) => (
                                    <div key={index} className="grid grid-cols-6 gap-y-1">
                                        <div className="col-span-4">
                                            <span>{item.company}</span> <span className="text-gray-400 text-sm">{item.role}</span>
                                        </div>
                                        <div className="text-sm text-gray-600 col-span-2">{item.year}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="mb-4 text-sm/5 font-semibold text-gray-900 ">Advisor</div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-8">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-1">
                                {[
                                    { year: "2021 — 2022", name: "The Preptrack Foundation", link: "https://preptrack.co.uk/" },
                                    { year: "2019", name: "Capitol Records 360" },
                                    { year: "2016 — 2018", name: "Amaliah", link: "https://www.amaliah.com/" },
                                    { year: "2013 — 2018", name: "Ignite", link: "https://www.ignite.io/" },
                                    { year: "2016 — 2017", name: "GetInfluence", note: "Acquired" },
                                    { year: "2014 — 2015", name: "Chew.tv", note: "Acquired" },
                                    { year: "2013 — 2014", name: "Huck Magazine", link: "https://www.huckmag.com/" },
                                    { year: "2014", name: "Jinn", note: "Acquired" },
                                ].map((advisor, index) => (
                                    <div key={index} className="grid grid-cols-6 gap-y-1">
                                        <div className="col-span-4">
                                            {advisor.link ? (
                                                <a href={advisor.link} target="_blank" rel="noopener noreferrer" className="border-b border-rose-600 border-dotted hover:border-0">
                                                    {advisor.name}
                                                </a>
                                            ) : (
                                                <span>{advisor.name}</span>
                                            )}
                                            {advisor.note && <span className="text-gray-400 text-sm ml-1">{advisor.note}</span>}
                                        </div>
                                        <div className="text-sm text-gray-600 col-span-2">{advisor.year}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="mb-4 text-sm/5 font-semibold text-gray-900 ">Speaker</div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-8">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-1">
                                {[
                                    { year: "2018", event: "New York Media Week" },
                                    { year: "2017", event: "Northside Innovation Festival NY" },
                                    { year: "2016", event: "Dallas Society of Visual Comms" },
                                    { year: "2015", event: "Percolate x Behance DesignTalks NY" },
                                    { year: "2013", event: "General Assembly" },
                                    { year: "2012 — 2013", event: "The Guardian Masterclasses" },
                                    { year: "2014", event: "Untangle The Web" },
                                    { year: "2013", event: "Pure London" },
                                    { year: "2011", event: "HyperIsland", note: "Visiting Lecturer" },
                                    { year: "2008 — 2010", event: "Newcastle City College", note: "Visiting Lecturer" },
                                ].map((item, index) => (
                                    <div key={index} className="grid grid-cols-6 gap-y-1">
                                        <div className="col-span-4">
                                            {item.event}
                                            {item.note && <span className="text-gray-400 text-sm ml-1">{item.note}</span>}
                                        </div>
                                        <div className="text-sm text-gray-600 col-span-2">{item.year}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="mb-4 text-sm/5 font-semibold text-gray-900 ">Judge</div>
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-1">
                                {[
                                    { year: "2018 — 2023", event: "IADAS Awards", note: "Executive Judge" },
                                    { year: "2018 — 2023", event: "Telly Awards" },
                                    { year: "2018", event: "MediaPost Appy Awards" },
                                ].map((item, index) => (
                                    <div key={index} className="grid grid-cols-6 gap-y-1">
                                        <div className="col-span-4">
                                            {item.event} {item.note && <span className="text-gray-400 text-sm ml-1">{item.note}</span>}
                                        </div>
                                        <div className="text-sm text-gray-600 col-span-2">{item.year}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="py-8">
                        <div className="sm:grid sm:grid-cols-4 sm:gap-4">
                            <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-8">
                                <div>
                                    <a href="/" class="underline hover:no-underline me-2">
                                        Home
                                    </a>
                                    <a href="/services" class="underline hover:no-underline">
                                        Work With Me
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
