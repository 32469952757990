import React, { useEffect, useState } from "react";

const YouTube = () => {
    const [video, setVideo] = useState(null);
    const [channelName, setChannelName] = useState(null);

    const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
    const PLAYLIST_ID = process.env.REACT_APP_PLAYLIST_ID;

    useEffect(() => {
        const fetchLatestVideo = async () => {
            try {
                const response = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${PLAYLIST_ID}&maxResults=50&key=${API_KEY}`);
                const data = await response.json();

                if (data.error) {
                    console.error("YouTube API Error:", data.error);
                } else if (data.items && data.items.length > 0) {
                    const latestVideoData = data.items[data.items.length - 1].snippet;
                    const videoId = latestVideoData.resourceId.videoId;

                    setVideo({
                        title: latestVideoData.title,
                        videoId: videoId,
                        thumbnail: latestVideoData.thumbnails.high.url,
                    });

                    const videoResponse = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${API_KEY}`);
                    const videoDetails = await videoResponse.json();

                    if (videoDetails.items && videoDetails.items.length > 0) {
                        const actualChannelId = videoDetails.items[0].snippet.channelId;
                        const actualChannelName = videoDetails.items[0].snippet.channelTitle;

                        setVideo((prevVideo) => ({
                            ...prevVideo,
                            channelId: actualChannelId,
                        }));

                        setChannelName(actualChannelName);
                    }
                } else {
                    console.warn("No videos found in playlist.");
                }
            } catch (error) {
                console.error("Error fetching YouTube video:", error);
            }
        };

        fetchLatestVideo();
    }, []);

    if (!video) return <p>Loading latest video...</p>;

    return (
        <div className="max-w-xl px-4 sm:px-4 lg:px-8 mx-auto py-8">
            <div className="space-y-4">
                <a href={`https://www.youtube.com/watch?v=${video.videoId}`} target="_blank" rel="noopener noreferrer" className="ring-1 ring-rose-600 rounded-full px-6 py-4 text-sm/5 text-center hover:ring-2 transition w-full block duration-500">
                    {video.title} by <span>{channelName ? <span>{channelName}</span> : "Loading..."}</span>
                </a>
            </div>
        </div>
    );
};

export default YouTube;
