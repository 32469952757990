import React, { useEffect } from "react";
import avatar from "./assets/avatar.png";
import Weather from "./Weather";
import YouTube from "./YouTube";

const Links = () => {
    useEffect(() => {
        document.title = "Lee Simpson";
        document.querySelector('meta[name="description"]')?.setAttribute("content", "Find me on LinkedIn, Bluesky, Threads, Instagram and GitHub.");
    }, []);
    return (
        <div>
            <div className="max-w-4xl px-4 sm:px-4 lg:px-8 mx-auto">
                <div className="divide-y divide-gray-300">
                    <div className="sm:px-0 py-8 items-center">
                        <div className="mt-1 text-sm/5 text-gray-700 sm:col-span-3 sm:mt-0 space-y-4 text-center">
                            <img alt="User Avatar" src={avatar} className="inline-block size-14 rounded-md" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-sm text-gray-900">Founder + Product Leader</span>
                </div>
            </div>

            <div className="max-w-xl px-4 sm:px-4 lg:px-8 mx-auto py-8">
                <div className="space-y-4">
                    {[
                        { name: "🔥 Creator Playground", url: "https://www.creatorplayground.io", label: "creatorplayground.io" },
                        { name: "Creator Stack", url: "https://www.creatorstack.io", label: "creatorstack.io" },
                        { name: "SMPSN", url: "https://www.smpsn-co.com", label: "smpsn-co.com" },
                    ].map((link, index) => (
                        <div key={index}>
                            <a href={link.url} target="_blank" class="group relative inline-flex h-20 items-center justify-center overflow-hidden rounded-md bg-white ring-1 hover:ring-2 ring-gray-950 px-6 text-gray-950 duration-500 w-full transition">
                                {link.name}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <div className="relative">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-sm text-gray-900">Social</span>
                </div>
            </div>
            <div className="max-w-xl px-4 sm:px-4 lg:px-8 mx-auto py-8">
                <div className="space-y-4">
                    {[
                        { name: "LinkedIn", url: "https://www.linkedin.com/in/leesimpson", label: "linkedin.com/in/leesimpson" },
                        { name: "Bluesky", url: "https://bsky.app/profile/itsleesimpson.bsky.social", label: "itsleesimpson.bsky.social" },
                        { name: "Threads", url: "https://www.threads.net/@itsleesimpson", label: "@itsleesimpson" },
                        { name: "Instagram", url: "https://www.instagram.com/itsleesimpson", label: "@itsleesimpson" },
                        { name: "GitHub", url: "https://github.com/itsleesimpson", label: "@itsleesimpson" },
                        { name: "Personal Website", url: "https://www.itsleesimpson.com", label: "itsleesimpson.com" },
                    ].map((link, index) => (
                        <div key={index}>
                            <a href={link.url} target="_blank" class="group relative inline-flex h-20 items-center justify-center overflow-hidden rounded-md bg-white ring-1 hover:ring-2 ring-gray-950 px-6 text-gray-950 duration-500 w-full transition">
                                {link.name}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <div className="relative">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-3 text-sm text-gray-900">Currently Watching</span>
                </div>
            </div>
            <YouTube />
            <div className="relative">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200" />
                </div>
            </div>
            <Weather />
        </div>
    );
};

export default Links;
