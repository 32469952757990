import React, { useEffect, useState } from "react";

const Weather = () => {
    const [weather, setWeather] = useState(null);

    useEffect(() => {
        const fetchWeather = async () => {
            try {
                const response = await fetch("https://api.open-meteo.com/v1/forecast?latitude=34.0522&longitude=-118.2437&current=apparent_temperature,is_day&temperature_unit=fahrenheit&timezone=America%2FLos_Angeles");
                const data = await response.json();
                setWeather({
                    temp: data.current.apparent_temperature, // Corrected key
                    isDay: data.current.is_day, // 1 = Day, 0 = Night
                });
            } catch (error) {
                console.error("Error fetching weather:", error);
            }
        };

        fetchWeather();
    }, []);

    if (!weather)
        return (
            <div className="text-center py-8">
                <p className="text-sm text-gray">Loading...</p>
            </div>
        );

    return (
        <div className="text-center py-8 text-sm text-gray-900">
            Made in{" "}
            <a href="https://www.google.com/maps/place/Culver+City,+CA/@34.0060577,-118.4502239,13z/data=!3m1!4b1!4m6!3m5!1s0x80c2ba1edb77739d:0x3185e9d14beb59fe!8m2!3d34.0211224!4d-118.3964665!16zL20vMHIwNHA?entry=ttu&g_ep=EgoyMDI1MDIyNS4wIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D" target="_blank" className="underline hover:no-underline">
                Culver City
            </a>{" "}
            • {weather.isDay ? "☀️" : "🌕"} {weather.temp}°F
        </div>
    );
};

export default Weather;
